@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,500;0,700;1,100;1,500&family=Ubuntu:wght@700&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,500;0,700;1,100;1,500&display=swap'); */

:root {
  /* SIZES */
  --max-page-width: 1000px;
  --max-nav-width: 500px;
  --header-height: 60px;
  --footer-height: 20vh;
  --min-app-height: calc(100vh + var(--footer-height));
  --min-page-height: calc(100vh - var(--header-height));
  --border-radius: 20px;
  --text-border-radius: 10px;
  --text-field-height: 30px;
  --text-field-font-size: 16px;


  /* COLORS AND FONTS*/
  --white: #ffffff;
  --dark-white: #eeeeee;
  --grey: #303030;
  --light-grey: #666666;
  --very-light-grey: #a3a3a3;
  /* --green: #006317; */
  --green: #06a551;
  --light-green: #e1ffe7;
  --orange: #df4600;
  --gold: #c05600;
  --purple: #4e0c68;
  --button: #7405a0;
  --red: rgb(200, 0, 0);
  --light-red: rgb(255, 239, 242);
  --bold-font: 'Ubuntu';
}
 
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #303030;
  color: var(--grey);
}
  

html, body{
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  overflow: overlay;
  min-height: 100vh;
}

.row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.col{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
} 

.bold{
    font-family: 'Ubuntu';
    font-family: var(--bold-font);
}


/** APP WRAP **/
/*
This wraps the whole app, including the navbar and footer, so that the site
takes up the whole screen.
*/
.outer-app-wrap{
    min-height: calc(100vh + 20vh);
    min-height: var(--min-app-height);
    justify-content: space-between;
    width: 100vw;
}

/*
This wraps the whole app except the navbar and footer, so that all child pages
can have a width of 100% and either be the right size if the screen is wide
enough, or have an appropriate margin if the screen is narrow.
*/
.inner-app-wrap{
    min-height: calc(100vh - 60px);
    min-height: var(--min-page-height);
    justify-content: flex-start;
    width: 95vw;
    max-width: 1000px;
    max-width: var(--max-page-width);
    white-space: pre-line;
}

/** HEADER/FOOTER **/
.nav-link{
    font-weight: 100;
    font-size: 20px;
    text-decoration: none;
}

.footer-link{
    font-size: 16px;
    margin: 2px 0px;
}

/** TEXT FIELDS **/
textarea,
input {
  width: 100%;
  resize: none;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.loading {
  margin: auto;
  min-height: 200px;
  justify-self: space-around !important;
}

/** TRANSITION TO LARGE TABLET (ie iPAD PRO) **/
@media screen and (max-width: 1100px) {
    .to-col-lg-tablet {
      display: flex;
      flex-direction: column;
    }
}

/** TRANSITION TO TABLET: **/
@media screen and (max-width: 850px) {
    .to-col-tablet{
      display: flex;
      flex-direction: column;
    }
}
  
/** TRANSITION TO PHONE: **/
@media screen and (max-width: 650px) {
    .to-col-phone{
        display: flex;
        flex-direction: column;
    }
}
  
